<template>
    <v-dialog
        v-model="dialog"
        max-width="600px"
        persistent
    >
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <!-- Edited users-->
            <v-card-text>
                <v-form v-model="isValid">
                    <v-container class="py-0">
                        <v-row>
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    v-model="editedItem.name.value"
                                    class="purple-input"
                                    :label="$t('admin.groups.name')"
                                    :error-messages="editedItem.name.error"
                                    :rules="[rules.required]"
                                    @keyup="editedItem.name.error = ''"
                                />
                            </v-col>

                            <v-col

                                cols="12"
                                sm="6"
                                class="d-flex flex-column justify-center "
                            >
                                <span
                                    v-if="editedItem.parentId.value"
                                >{{ $t('admin.groups.childrenFrom') }}</span>
                                <span v-else> {{ $t('admin.groups.withoutParent') }}</span>

                                <span v-if="editedItem.parentId.value">{{ getElement(editedItem.parentId.value) }}</span>
                            </v-col>
                        </v-row>

                        <visualEditor
                            :type-editor="1"
                            :url-main="urlMain"
                            :body="editedItem.body.value"
                            :words-to-select="wordsToSelect"
                            @update:body="editedItem.body.value = $event"
                        />
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                    {{ $t('common.confirmDialogCancel') }}
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    :disabled="!isValid && !serverCheck"
                    @click="save"
                >
                    {{ $t('common.dialogSave') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from '@/plugins/axios'
/* import { mapGetters } from 'vuex'
import item from '@/components/base/item' */
import rules from '@/helpers/rulesValidate'

import visualEditor from '@/components/recognitions/form/visualEditor'

export default {
    components: { visualEditor },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        item: {
            type: Object,
            required: false
        },
        items: {
            type: Array,
            required: true,
            default() {
                return [];
            }
        }
    },
    data: function () {
        return {
            urlMain: '/groups',
            rules: {
                ...rules
            },
            editedItem: this.getEditedItem(),
            isValid: true,
            serverCheck: false,
            wordsToSelect: []
        }
    },
    computed: {
        formTitle() {
            return this.item && this.item.id
                ? this.$t('admin.groups.edit')
                : this.$t('admin.groups.new')
        }
    },
    watch: {
        dialog(val) {
            if (val) {
                this.editedItem = this.getEditedItem()
            }
        }
    },
    created() {
        this.fetchWords();
    },
    methods: {
        getElement(id) {
            /* console.log(id)
            console.log(this.items) */
            if (id) {
                const item = this.items.find(e => e.id == id);
                return item ? item.attributes.name : "";
            }
            return "";
        },
        getEditedItem() {
            const item = this.item

            var empty = !item

            return {
                id: empty ? '' : item.id,
                name: {
                    value: empty ? '' : item.name,
                    error: ''
                },
                body: {
                    value: empty ? '' : item.body,
                    error: ''
                },
                parentId: {
                    value: empty ? '' : item.parentId,
                    error: ''
                }
            }
        },

        initialize($data) {
            this.items = $data.data

            this.pagination.totalItems = $data.meta.page.total
        },

        close() {
            this.$emit('update:dialog', false)
        },

        async save() {
            var dataSubmit = {
                data: {
                    type: 'groups',
                    attributes: {
                        name: this.editedItem.name.value,
                        body: this.editedItem.body.value,
                        'parent-id': this.editedItem.parentId.value ?? ""
                    }
                }
            }

            let urlAppend = ''
            let method = 'post'
            if (this.editedItem.id) {
                urlAppend = '/' + this.editedItem.id
                dataSubmit.data.id = '' + this.editedItem.id

                method = 'patch'
            }

            try {
                const result = await axios({
                    method: method,
                    url: this.urlMain + urlAppend,
                    data: dataSubmit
                })

                if (result.status === 200 || result.status === 201 || result.status === 204) {
                    this.close()

                    const actionMSG = this.editedItem.id ? 'updatedOK' : 'createdOK'
                    this.$store.dispatch('snackbar/success', this.$t('common.' + actionMSG))
                }
            } catch (error) {
                if (error.response.status === 422) {
                    /* console.log('********** errors ********')

            console.log(error.response.data.errors) */

                    if (error.response?.data?.errors?.length) {
                        error.response.data.errors.forEach((e) => {
                            if (e.source.pointer === '/data/attributes/name') {
                                this.editedItem.name.error = e.detail
                            }
                            if (e.source.pointer === '/data/attributes/display_name') {
                                this.editedItem.display_name.error = e.detail
                            }
                        })
                    }
                }

                this.$store.dispatch('snackbar/error', this.$t('common.accionError'))
            }

            this.$emit('update:table')
        },
        async fetchWords() {
            try {
                const words = await axios
                    .get('words')

                /* console.log('***** words.data ****')
          console.log(words.data.data) */

                this.wordsToSelect = words.data.data;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        }
    }
}
</script>
